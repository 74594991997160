.add-event-form {
  display: flex;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .form-btns {
    gap: 10px;
  }
}

.rbc-day-slot .rbc-event-content {
  width:auto !important;
}
.react-big-calendar-time-view {
  /* max-height: 500px;  */
  /* Adjust height as needed */
  /* overflow-y: auto; */
}

.bold-title{
  font-weight: bold;
}

.radio-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding-left: 25px;
  /* margin-top: 25px; */
  height: 70px;

  .appointment-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .appointment-btn>label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
  }
}

.add-app-btn {
  border-radius: 4px;
  padding: 10px 16px 10px 16px;
  background-color: #1334b9 !important;
}

.cancel-btn {
  background-color: white !important;
  border-color: #1334b9 !important;
  color: #1334b9 !important;
}

.close-events {
  padding: 10px;
  right: 0px;
  position: absolute;
  cursor: pointer;
}

.heading {
  display: block !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  text-align: left;
}

.event-header {
  height: 70px;
  background: #FFFFFF;
  border-bottom: 1px solid #E5E7EB;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  gap: 10px;
}

.events-btns {
  gap: 10px;
}

.add-new-btn {
  display: flex;
  justify-content: flex-end;
  padding: 20px 50px 20px 20px;
}

.css-1nmdiq5-menu {
  position: relative !important;
}

.css-13cymwt-control {
  height: 55px;
  background: #FFFFFF;
  border: 1px solid #D1D5DB;
  border-radius: 7px !important;
  border-color: #D1D5DB !important;
}

.select-placeholder-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9CA3AF;
}

.calendar-container {
  max-height: 78vh;
  overflow-y: auto;
  width: 95%;
  margin: auto;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;

  .rbc-month-header {
    background: #f9fafb;
    border-bottom: 1px solid #9ca3af;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .rbc-header {
      border: none;
    }
  }
}

.calendar-container::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.calendar-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

/* .rbc-month-row {
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 100px !important;
} */



.calendar-container::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Radius of the scrollbar thumb */
}

.calendar-container::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}

.rbc-btn-group {
  display: flex;
  gap: 10px;
}

.rbc-btn-group>button {
  color: #1334b9;
  border: 1px solid #1334b9;
  border-radius: 4px;
}

.rbc-active {
  background: #1334b9 !important;
  border: 1px solid #1334b9 !important;
  color: white !important;
}

.rbc-event-content>div {
  /* display: flex; */
}

.editdelapp-wrapper {
  display: flex;
  justify-content: flex-start;
  /* gap: 10px; */
  /* width: 50%; */
  /* align-items: center; */

  .event-title {
    /* width: 70%; */
    text-wrap: wrap;
  }
}

.edit-del-app {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* width: 50%; */
}

.right-div {
  gap: 0px !important;
  position: relative;
  padding: 6px;
}

.rbc-event {
  /* display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 20% !important; */
  border-color: white !important;
  width:auto !important;
  .rbc-event-content {
    /* text-align: center; */
  }

  /* .rbc-time-column {
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    overflow: scroll;
}
   */

   /* .rbc-day-slot {
    width: auto !important;
    height: auto !important;
    .rbc-events-container {
      width: auto !important;
        margin-right: 10px;
    }
} */

  .edit-appointment {
    height: 14px;
    width: 14px;
  }
}

.startdate-validation-error>label {
  top: -10px !important;
}