.dashboard-header {
  /* font-family: 'Arian LT' !important; */
  height: 80px;
}
.dashboard-heading {
  font-family: 'Arian LT' !important;
  font-weight: 700;
  font-size: 26px;
  line-height: 37px;
}
.right-img {
  cursor: pointer;
}
.notifications-wrapper {
  top: 8px !important;
  left: -75px !important;
  padding: 0px 0px 20px 0px;
  border-radius: 16px;
  border: 1px;
  .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 37px;
    width: 630px;
    max-height: 500px;
  }
  .blue-icon {
    height: 16px;
    width: 12px;
  }
  .menu-title {
    width: 330px;
    text-wrap: wrap;
    font-family: 'Roboto' !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.15000000596046448px;
    text-align: left;
  }
  .menu-date {
    font-family: 'Roboto' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.15000000596046448px;
    text-align: left;
    color: #9ca3af;
  }
  .noti-divider {
    color: #9ca3af;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .noti-heading {
    background-color: #f3f4f6 !important;
    padding: 15px, 25px, 15px, 25px !important;
    font-family: 'Roboto' !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    letter-spacing: -0.5px !important;
    text-align: left !important;
    height: 70px;
  }
  .menu-item-noti {
    display: flex !important;
    justify-content: space-between !important;
    padding: 15px !important;
    min-height: 60px !important;
  }
  .menu-item-div1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 360px;
    gap: 10px;
  }
}
.right-img-icon{
  height: 44px;
  width: 46px;
  border-radius: 50%;
  cursor: pointer;
}
.cursor-pointer{
  cursor: pointer;
}
.user-wrapper {
  top: 13px !important;
  left: -14px !important;
  padding: 0px 0px 20px 0px;
  border-radius: 16px;
  border: 1px;

  .user-item-1{
    padding: 14px 16px;
    border-radius: 4px;
    gap: 10px;
    border: 1px solid #E5E7EB;
    width: 250px;
  }
}

