.resetpass-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;   
    color: #000000; 
}

.resetpass-subheading{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px;
color: #9CA3AF;
}
.email-sent-heading{
    font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 28px;
color: #000000;
}