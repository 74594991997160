.login-footer {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.footer-first {
  color: #9ca3af;
}
.footer-sec {
  color: #1334b9;
  cursor: pointer;
}
