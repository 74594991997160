.report-popup {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #ffffff;
  margin: 0px;
  width: 503px;
  height: 100vh;
  overflow-y: auto;
  left: auto;

  .modal-content {
    border: none !important;
    height: 100%;
  }
  .modal-body {
    background: #ffffff;
    padding-top: 0px;
  }
  .report-label {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #6b7280;
    margin-top: 20px;
  }

  .modal-footer {
    border: none;
    position: relative;
    bottom: 0px;
    margin: auto;
    width: 100%;
  }
  .modal-dialog {
    height: 100%;
    margin-top: 0px;
  }
  .date-filter {
    gap: 15px;
  }
  .css-b62m3t-container {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9ca3af;
  }
}

.red-dot {
  position: absolute;
  width: 10px;
  border-radius: 50%;
  height: 10px;
  left: 39px;
  top: 3px;
  background: #cf433f;
}

.reset-icon{
  height: 24px;
  cursor: pointer;
}