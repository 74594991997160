table {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 97%;
  margin-left: 3%;
  margin-top: 2%;
  overflow-y: scroll;
  /* height: 300px; */
  /* border-collapse: separate; */
  border-spacing: 0;
  border: none;
}
.pilot-label{
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #374151;
}
.table-container {
  height: calc(100vh - 210px); /* Adjust the height as needed */
  overflow-y: auto;
  padding: 30px;

  .table-cls {
    margin: 0px !important;
    width: 100%;
  }
  .search-bar {
    position: relative;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .search-icon svg {
    display: block;
  }

  .SearchLabel {
    padding-left: 10px;
  }
  .searchpilot {
    padding-left: 35px !important;
  }
}

table tbody {
  background-color: white;
  border: 1px solid #d1d5db;
}

table td {
  /* border: 1px solid #ddd; */
  padding: 8px;
  font-family: Roboto;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid #ecebe9;
  border-top: none;
}

table th {
  border-bottom: 1px solid #ecebe9;
  padding: 8px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

table th,
tfoot td {
  border: 1px solid #d1d5db;
  padding-top: 12px;
  padding-left: 10px;
  padding-bottom: 12px;
  /* text-align: center; */
  background: #f3f4f6;
  /* background-color: #f2f2f2; */
  /* color: white; */
}
table tr {
  padding-left: 10px;
  height: 80px !important;
}

.paginationText {
  height: 24px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* display: flex; */
  align-items: center;

  /* Dark-P */

  color: #2e3b36;
}
.file-input-list {
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #111827;
}
.color-picker-wrapper{
  display: flex;
  gap: 16px;
}
.color-hex-val{
  display: flex;
    justify-content: flex-start;
    gap: 20px;
    border: 1px solid #D1D5DB;
    height: 70px;
    border-radius: 8px;
    align-items: center;
    width: 325px;
    padding: 16px;
}
.color-hex{
  font-family: 'Roboto' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #111827;
}
.color-picker{
  box-shadow: 0px 0px 8px 0px #00000026 !important;
}
.file-size-label {
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6b7280;
}
.pdf-name-cont {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* display: flex; */
  align-items: center;
  color: #111827;
  gap: 20px;
  .pdf-img {
    margin-right: 15px;
  }
}
.doc-tr > td {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}
.SearchLabel {
  height: 40px;
  width: 100%;
  left: 0px;
  top: 0px;
  align-items: center;
  background: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
}

.addNewButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  /* width: 113px; */
  height: 40px;

  /* Brand Primary */

  background: #1334b9;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.paginationBox {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 40px;

  background: #ffffff;
  /* Neutral / Neutral 200 */
  border-top: 1px solid #e5e7eb;
}

.paginationButton {
  background-color: white;
  /* width: 80px; */
  margin-right: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #e8e8e8;
}

.activeButton {
  background-color: #1334b9;
}

.documentsTableHead {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 24px; */
  /* identical to box height, or 171% */

  /* display: flex;
  align-items: center; */

  /* Neutral / Neutral 900 */

  color: #111827;
}

.document-Table {
  width: 100% !important;
}
.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
}
.img-name-cont {
  display: flex;
  align-items: center;
  gap: 18px;
}

.pilot-cont {
  .nav-link {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #9ca3af !important;
    border: none;
  }
  .nav-item {
    .active {
      border-bottom: 2px solid #1334b9 !important;
    }
  }
  .active {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    leading-trim: both !important;
    text-edge: cap !important;
    color: #1334b9 !important;
    border: none;
  }
  .personalLabels {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #111827 !important;
  }
}
.no-doc-added {
  color: #9ca3af;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.doc-upload-bottom {
  margin: 70px 0px 60px 0px;
}
.curPointer {
  cursor: pointer;
}
.no-doc-span {
  color: #1334b9;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
