* {
  /* font-family: 'Roboto'; */
  font-family: 'Roboto' !important;
  font-style: normal !important;
  overflow: hidden;
}
::-ms-reveal {
  display: none !important;
}
.text-danger{
  margin-top: 0.25rem;
    font-size: 0.875em;
}
@font-face {
  font-family: 'Arian LT';
  src: url('../src/fonts/arain-lt.woff') format('woff');
  /* url('../src/fonts/arian-lt.woff') format('woff'); */
  font-weight: normal;
  font-style: normal;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

.Auth-form-container {
  width: 90%;
  max-height: 100vh;
  /* height: 80vh; */
}

.gray-background {
  background-color: grey;
}

.gray-background .react-bootstrap-table th {
  background-color: grey;
  color: white;
}

.gray-background .react-bootstrap-table .react-bootstrap-table-pagination {
  background-color: grey;
  color: white;
}

.Logo-img {
  height: 100%;
}

.Auth-form {
  width: 95%;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 10%;
  padding-bottom: 10%;
  border-radius: 8px;
  background-color: white;
  margin: 10px;
  display: flex;
  justify-content: center;
}

.Auth-form-content {
  padding-left: 10%;
  padding-right: 10%;
}

.Auth-form-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.Auth-form-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #9ca3af;
}
.zeropadding {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.success-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  /* margin-top: 90px !important; */
  height: 100vh;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
/* .forgot-password
{
  color: #9CA3AF;
} */
.help {
  color: #1334b9;
  text-decoration: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.input-group-append {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.form-control {
  width: 100%;
  /* padding-right: 50px; adjust as needed */
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.side-bar-container {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  height: 100%;
}
main {
  width: 100%;
  background-color: #f3f4f6;
  overflow-y: auto;
}

.custom-header-class {
  background-color: #f2f2f2;
}

.sidebar {
  background: white;
  color: black;
  height: 100vh;
  width: 25%;
  border-right: 1px solid #e5e7eb; /* transition: all 0.5s; */
  position: relative;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 18px 15px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  cursor: pointer;
}
.expand_bar {
  float: right;
  .bar-img {
    position: absolute;
    bottom: 20px;
    height: 50px;
    width: 50px;
    right: 5px;
  }
}
.collapse_bar {
  justify-content: center;

  .bar-img {
    position: absolute;
    bottom: 20px;
    height: 50px;
    width: 50px;
  }
}
.link {
  display: flex;
  color: black;
  align-items: center;
  padding: 4% 10%;
  gap: 15px;
  text-decoration: none;
  /* transition: all 0.5s; */
}
.collapsedLink {
  display: flex;
  color: black;
  padding: 10% 15%;
  gap: 10%;
  text-decoration: none;
  justify-content: center;
  /* transition: all 0.5s; */
}
.link:hover .collapsedLink:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active {
  background: rgba(19, 52, 185, 0.1);
  color: #000;
  align-items: center;
  .link_text {
    color: #1334b9;
  }
}
.icon,
.link_text {
  font-size: 20px;
}
.link_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  leading-trim: both;
  text-edge: cap;
  color: #6b7280;
}

#search-bar-0-label {
  display: none;
}

.backButton {
  background-color: white !important;
  border-color: #1334b9 !important;
  color: #1334b9 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 40px;
}
.editPilot {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.viewPilot-Box {
  width: 24%;
  float: left;
  /* margin-left: 1%; */
  margin-right: 1%;
}
.viewPilot-Box32 {
  width: 32.25%;
  float: left;
  margin-right: 1%;
}

.disabledColor {
  background: #f3f4f6 !important;
  border: 1px solid #9ca3af !important;
  color: #9ca3af !important;
}
.showdisabledColor {
  .css-1f43avz-a11yText-A11yText {
    height: 58px;
  }  
  .css-olqui2-singleValue {
    color: #9ca3af !important;
  }
  .css-olqui2-singleValue {
    color: black;
    font-size: 1rem;
    font-weight: 400;
  }

  .css-16xfy0z-control {
    height: 58px;
    background: #f3f4f6 !important;
    border: 1px solid #9ca3af !important;
  }
}

.css-t3ipsp-control {
  border: 1px solid #d1d5db;
  border-radius: 6px !important;
  border-color: #d1d5db !important;
  height: 58px !important;
  box-shadow: none !important;
  /* border: 1px solid #9ca3af !important; */
}
.css-13cymwt-control {
  height: 58px !important;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 6px !important;
  border-color: #d1d5db !important;
}
.css-1dimb5e-singleValue {
  color: black !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.genderlabel {
  left: -5px !important;
  top: -13px !important;
}

.rdt_TableHeadRow {
  background-color: #f3f4f6 !important;
  /* border: 1px solid black; */
}
.rdt_Table {
  /* margin-left: 20px !important; */
}

.greyLabel {
  color: #9ca3af;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.greyLabelTop {
  color: #9ca3af;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  /* justify-content: center; */
  align-items: start;
}
.greyLabel::after {
  /* background-color: #f3f4f6 !important; */
  background-color: transparent !important;
}
.searchpilot {
  padding-left: 35px !important;
}
.uploadDocumentLabel {
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #6b7280;
}

.uploaddocumentInput {
  box-sizing: border-box;
  padding: 10px 16px;
  gap: 10px;
  width: 100%;
  height: 36px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9ca3af;
}

.uploadDocumenUploadBox {
  height: 97px;
  width: 100%;
  /* left: 0px;
top: 0px; */
  border-radius: 4px;
  padding: 10px 16px 10px 16px;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
}

.personalLabels {
  font-family: Roboto !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  padding: 10px !important;
}

.modalTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.modalBody {
  /* width: 550px; */
  /* height: 100px;  */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  padding: 10px;
  color: #000000;
  background-color: #f3f4f6;
}

.modalOkButton {
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: #1334b9 !important;
  left: 455px;
  top: 15px;
  border-radius: 4px;
  padding: 10px 16px 10px 16px;
  background-color: #1334b9 !important;
}

.file-input-container {
  position: relative;
  display: inline-block;
}

.hidden-input {
  display: none;
}

.custom-file-input {
  display: inline-block;
  padding: 8px 20px;
  /* background-color: #e9e9e9; */
  color: #555555;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-input svg {
  margin-right: 5px;
}
.form-floating-gender {
  position: relative;
}

.form-floating-gender label {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 0.2s ease-out;
  opacity: 0.6;
}

.form-floating-gender input:not(:placeholder-shown),
.form-floating-gender select:not(:placeholder-shown) {
  padding-top: 1.5rem;
}

.form-floating-gender input:not(:placeholder-shown) + label,
.form-floating-gender select:not(:placeholder-shown) + label {
  font-size: 0.75rem;
  transform: translateY(-0.5rem) scale(0.85);
  opacity: 1;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1334b9;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #03124e;
}
.rbc-toolbar-label {
  font-weight: 600;
}
