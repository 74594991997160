.form-title {
    font-family: 'Arian LT' !important;
    font-weight: 700;
    font-size: 26px;
    line-height: 37px;
    margin-bottom: 10px;
}

.form{
    padding-top: 5%;
}