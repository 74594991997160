.org-txt {
  font-family: 'Roboto' !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #9ca3af;
}
.w49{
    width: 49%;
}