.manage-form-wrapper {
  margin-right: auto;
  margin-top: 25px;
  width: 90%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 35px;

  .risk-ass-div {
    border-radius: 8px;
    background: white;
    height: 160px;
    padding: 20px;
  }
  .risk-ass-first-row {
    display: flex;
    justify-content: space-between;
  }
  .risk-head {
    font-family: 'Roboto' !important;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #111827;
  }
  .edit-risk-btn {
    float: right;
    background-color: rgb(19, 52, 185);
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: rgb(19, 52, 185);
  }
  .risk-ass-sec-row {
    font-family: 'Roboto' !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
  }
}

.risk-ass-head {
  font-family: 'Roboto' !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.risk-add-head {
  border-bottom: 1px solid rgb(229, 231, 235);
  font-family: 'Roboto' !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding: 15px 15px 10px;
}
.question-option-wrapper {
  padding: 15px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.question-head {
  font-family: 'Roboto' !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}
.edit-del-ques {
  display: flex;
  gap: 15px;
}
.question-input {
  height: 50px;
}
.edit-ques {
  cursor: pointer;
  height: 50px;
  width: 50px;
}
.choice-option-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}
.ques-title {
  font-family: 'Roboto' !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.question-border {
  padding: 15px 15px 20px 15px;
  border-radius: 8px;
  border: 1px solid rgb(229, 231, 235);
}
.add-new-ques-wrapper {
  display: flex;
  justify-content: flex-end;
}
.add-ques-btn {
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  border: 1px solid #1334b9;
  color: #1334b9;
  background-color: white;
}
.add-choice {
  display: flex;
  gap: 10px;
  align-items: center;
  .add-choice-img {
    height: 24px;
    width: 24px;
  }
  .add-choice-txt {
    font-family: 'Roboto' !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1334b9;
  }
}
.risk-total {
  /* width: 107px; */
  height: 39px;
  padding: 10px 20px 10px 20px;
  border-radius: 50px;
  gap: 10px;
  background-color: #dbeafe;
  font-family: 'Roboto' !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.risk-range {
  font-family: 'Roboto' !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}
.low-risk-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .low-risk-range {
    font-family: 'Roboto' !important;
    font-size: 20px;
    font-weight: 500;
    /* line-height: 23px; */
    letter-spacing: 0em;
    text-align: left;
    color: #111827;
  }
  .low-risk-range-part2 {
    font-family: 'Roboto' !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #9ca3af;
  }
}
.choice-label{
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #374151;
}
