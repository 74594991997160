table {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 97%;
  margin-left: 3%;
  margin-top: 2%;
  overflow-y: scroll;
  /* height: 300px; */
  /* border-collapse: separate; */
  border-spacing: 0;
  border: none;
}
.date-validation-error > label{  
    top: -20px;
}
.table-container {
  height: calc(100vh - 210px); /* Adjust the height as needed */
  overflow-y: auto;
  /* padding: 30px;   */

  .table-cls{
    margin: 0px !important;
    width: 100%;
  }
  .search-bar {
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .search-icon svg {
    display: block;
  }
  
  .SearchLabel {
    padding-left: 10px;
  }
}


table tbody {
  background-color: white;
  /* border: 1px solid #ECEBE9 */
  border: none;

}

table td {
  /* border: 1px solid #ddd; */
  padding: 8px;
  color: black;
  font-family: Roboto;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* border-bottom: 1px solid #ECEBE9; */
  border: none !important;
    border-top: none;
}
.aircraft-cont{
  .nav-link{
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #9CA3AF !important;
    border: none;
  }
  
  .nav-item{
    .active{
      border-bottom: 2px solid #1334B9 !important;
    }
  }
  .active{
    /* border-bottom: 2px solid #1334B9 !important; */
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    leading-trim: both !important;
    text-edge: cap !important;
    color: #1334B9 !important;
    border: none;
  }
  .personalLabels{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
color: #111827;
  }
}

table th {
  border-bottom: 1px solid #ECEBE9;    padding: 8px;   
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: black;

}

table tr{
  border: 1px solid #ECEBE9;
}

table th,
tfoot td {
  border: 1px solid #D1D5DB;
  padding-top: 12px;padding-left: 10px;
  padding-bottom: 12px;
  /* text-align: center; */
  background: #F3F4F6;
  color: black;
  /* background-color: #f2f2f2; */
  /* color: white; */
}
table tr{
  padding-left: 10px;
}

.paginationText{
  height: 24px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */

/* display: flex; */
align-items: center;

/* Dark-P */

color: #2E3B36;
}

.SearchLabel {
  height: 40px;
  width: 100%;
  left: 0px;
  top: 0px;
  /* padding: 10px 15px 10px 15px; */
  align-items: center;
background: #F9FAFB;
border: 1px solid #D1D5DB;
border-radius: 8px;

}

.addNewButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  /* width: 113px; */
  height: 40px;

  /* Brand Primary */

  background: #1334B9;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

}

.paginationBox {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 40px;


  background: #FFFFFF;
  /* Neutral / Neutral 200 */
  border-top: 1px solid #E5E7EB;
}

.paginationButton {

  background-color: white;
  /* width: 80px; */
  margin-right: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border:1px solid #E8E8E8;


}

.activeButton {
  background-color: #1334B9;
}


.documentsTableHead {

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 24px; */
  /* identical to box height, or 171% */

  /* display: flex;
  align-items: center; */

  /* Neutral / Neutral 900 */

  color: #111827;
}

.documentTable
{
  width: 94% !important;
}
.profile-img{
  width: 40px;
    height: 40px;
    border-radius: 100px;
    object-fit: cover;
}
.img-name-cont{
  display: flex;
    align-items: center;
    gap: 18px;
}