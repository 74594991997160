table {
    font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 97%;
    margin-left: 3%;
    margin-top: 2%;
    overflow-y: scroll;
    /* height: 300px; */
    /* border-collapse: separate; */
    border-spacing: 0;
    border: none;
  }
  .table-container {
    height: calc(100vh - 210px); /* Adjust the height as needed */
    overflow-y: auto;
    /* padding-left: 34px;
    padding-top: 30px; */
padding: 30px;
    .table-cls{
      margin: 0px !important;
      width: 100%;
    }
  }
  
  
  table tbody {
    background-color: white;
    border: 1px solid #D1D5DB;
  
  }
  
  .search-bar {
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .search-icon svg {
    display: block;
  }
  
  .SearchLabel {
    padding-left: 10px;
  }
  table td {
    /* border: 1px solid #ddd; */
    padding: 8px;
    font-family: Roboto;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid #ECEBE9;
      border-top: none;
  }
  
  table th {
    border-bottom: 1px solid #ECEBE9;    padding: 8px;   
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  
  }
  
  
  
  table th,
  tfoot td {
    border: 1px solid #D1D5DB;
    padding-top: 12px;padding-left: 10px;
    padding-bottom: 12px;
    /* text-align: center; */
    background: #F3F4F6;
    /* background-color: #f2f2f2; */
    /* color: white; */
  }
  table tr{
    padding-left: 10px;
  }
  
  .paginationText{
    height: 24px;
  
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  
  /* display: flex; */
  align-items: center;
  
  /* Dark-P */
  
  color: #2E3B36;
  }
  
  .SearchLabel {
    height: 40px;
    width: 100%;
    left: 0px;
    top: 0px;
    /* padding: 10px 15px 10px 15px; */
    align-items: center;
  background: #F9FAFB;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  
  }
  
  .addNewButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
  
    /* width: 113px; */
    height: 40px;
  
    /* Brand Primary */
  
    background: #1334B9;
    border-radius: 4px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
  
  }
  
  .paginationBox {
    box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 22px 40px;
  
  
    background: #FFFFFF;
    /* Neutral / Neutral 200 */
    border-top: 1px solid #E5E7EB;
  }
  
  .paginationButton {
  
    background-color: white;
    /* width: 80px; */
    margin-right: 5px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border:1px solid #E8E8E8;
  
  
  }
  
  .activeButton {
    background-color: #1334B9;
  }
  
  
  .documentsTableHead {
  
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 24px; */
    /* identical to box height, or 171% */
  
    /* display: flex;
    align-items: center; */
  
    /* Neutral / Neutral 900 */
  
    color: #111827;
  }
  
  .documentTable
  {
    width: 94% !important;
  }